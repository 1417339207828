import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import { Badge, Col, Container, Row } from "react-bootstrap"
import FeedbackSVG from "../../../assets/images/campaign-whatsapp/undraw_feedback_h2ft.svg"
import LoginSVG from "../../../assets/images/campaign-whatsapp/undraw_Login_v483.svg"
import MessageSVG from "../../../assets/images/campaign-whatsapp/undraw_message_sent_1030.svg"
import NotifySVG from "../../../assets/images/campaign-whatsapp/undraw_notify_88a4.svg"
import "./scss/index.scss"

const Steps = () => {
  const intl = useIntl()

  return (
    <section className="py-8 steps bg-light">
      <Container>
        <Row className="mb-5 pb-5">
          <Col className="text-center">
            <h2>{intl.formatMessage({ defaultMessage: "How does it work?" })}</h2>
            <span className="lead">
              {intl.formatMessage({
                defaultMessage:
                  "Event guests opt-in at the entrance. They receive messages via WhatsApp to provide real-time feedback in their own words. Feedback appears instantly in your dashboard.",
              })}
            </span>
          </Col>
        </Row>

        <Row className="bg-white">
          <Col xs={12} className="mt-n5 text-center">
            <Badge className="my-3 whatsapp-bg-color">
              <p className="lead mb-0">
                {intl.formatMessage({ defaultMessage: "A step-by-step guide" })}
              </p>
            </Badge>
          </Col>

          {/* Text left */}
          <Row className="py-5 mx-5 align-items-center">
            <Col xs={{ span: 4, order: 2 }} className="text-center py-3">
              <img src={LoginSVG} alt="..." className="steps__step-svg" data-aos="fade-left" />
            </Col>
            <Col xs={{ span: 8, order: 1 }} data-aos="fade-right">
              <Badge className="my-3 whatsapp-bg-color">
                <p className="lead mb-0">{intl.formatMessage({ defaultMessage: "Step 1" })}</p>
              </Badge>
              <h2 className="font-weight-bold">
                {intl.formatMessage({ defaultMessage: "Event guests opt-in at the entrance" })}
              </h2>

              <p className="font-size-lg text-muted mb-0">
                {intl.formatMessage({
                  defaultMessage: "The opt-in is fast and easy via QR code or short link.",
                })}
              </p>
            </Col>
          </Row>

          {/* Text right */}
          <Row className="py-5 mx-5 align-items-center">
            <Col xs={{ span: 4, order: 1 }} className="text-center py-3">
              <img src={MessageSVG} alt="..." className="steps__step-svg" data-aos="fade-right" />
            </Col>
            <Col xs={{ span: 8, order: 2 }} data-aos="fade-left">
              <Badge className="my-3 whatsapp-bg-color">
                <p className="lead mb-0">{intl.formatMessage({ defaultMessage: "Step 2" })}</p>
              </Badge>
              <h2 className="font-weight-bold">
                {intl.formatMessage({
                  defaultMessage: "Automated messages via WhatsApp to collect real-time feedback",
                })}
              </h2>

              <p className="font-size-lg text-muted mb-0">
                {intl.formatMessage({
                  defaultMessage:
                    "Your customers will be asked a simple question and then share feedback, in their own words. No extra work needed. All messages are scheduled and send automatically to collect feedback while it matters most.",
                })}
              </p>
            </Col>
          </Row>

          {/* Text left */}
          <Row className="py-5 mx-5 align-items-center">
            <Col xs={{ span: 4, order: 2 }} className="text-center py-3">
              <img src={FeedbackSVG} alt="..." className="steps__step-svg" data-aos="fade-left" />
            </Col>
            <Col xs={{ span: 8, order: 1 }} data-aos="fade-right">
              <Badge className="my-3 whatsapp-bg-color">
                <p className="lead mb-0">{intl.formatMessage({ defaultMessage: "Step 3" })}</p>
              </Badge>
              <h2 className="font-weight-bold">
                {intl.formatMessage({
                  defaultMessage:
                    "Feedback arrives in real-time for you to explore and act instantly",
                })}
              </h2>

              <p className="font-size-lg text-muted mb-0">
                {intl.formatMessage({
                  defaultMessage:
                    "As soon as a customer shares feedback, it is instantly available to you and your team in your dashboard for exploration.",
                })}
              </p>
            </Col>
          </Row>

          {/* Text right */}
          <Row className="py-5 mx-5 align-items-center">
            <Col xs={{ span: 4, order: 1 }} className="text-center py-3">
              <img src={NotifySVG} alt="..." className="steps__step-svg" data-aos="fade-right" />
            </Col>
            <Col xs={{ span: 8, order: 2 }} data-aos="fade-left">
              <Badge className="my-3 whatsapp-bg-color">
                <p className="lead mb-0">{intl.formatMessage({ defaultMessage: "Step 4" })}</p>
              </Badge>
              <h2 className="font-weight-bold">
                {intl.formatMessage({ defaultMessage: "Send announcements directly via WhatsApp" })}
              </h2>

              <p className="font-size-lg text-muted mb-0">
                {intl.formatMessage({
                  defaultMessage:
                    "Reach your guests where their attention is, directly via WhatsApp. You cannot only collect feedback but also share important messages with your audience.",
                })}
              </p>
            </Col>
          </Row>
        </Row>
      </Container>
    </section>
  )
}

export default Steps
