import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import { Container, Row } from "react-bootstrap"
import MindConference2 from "../../../assets/images/campaign-whatsapp/mind-conference-2.png"
import MindConference from "../../../assets/images/campaign-whatsapp/mind-conference.png"
import iPhoneX from "../../../assets/images/devices/iphonex.svg"

const Hero = () => {
  const intl = useIntl()

  return (
    <section className="pt-6 pt-md-8">
      <Container>
        <Row className="align-items-center justify-content-center">
          <div className="col-10 col-sm-8 col-md-4 order-md-2">
            {/* iPhone + iPhone*/}
            <div className="device-combo device-combo-iphonex-iphonex mb-6 mb-md-0">
              {/* iPhone */}
              <div className="device device-iphonex" data-aos="fade-left">
                <img src={MindConference} className="device-screen" alt="..." />
                <img src={iPhoneX} className="img-fluid" alt="..." />
              </div>

              {/* iPhone */}
              <div className="device device-iphonex" data-aos="fade-left" data-aos-delay="150">
                <img src={MindConference2} className="device-screen" alt="..." />
                <img src={iPhoneX} className="img-fluid" alt="..." />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-8 col-lg-5" data-aos="fade-right">
            {/* Heading */}
            <h1
              className="font-weight-bold"
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  defaultMessage:
                    'The fastest and easiest way to gather immediate actionable <b>real-time guest feedback</b> via <b><span class="text-whatsapp">WhatsApp</span> on your event</b>.',
                }),
              }}
            />

            {/* Text */}
            <p
              className="font-size-lg text-muted mb-6"
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  defaultMessage:
                    "Take actions <b>before</b> the event is over. No technical knowledge required.",
                }),
              }}
            />
          </div>
        </Row>
      </Container>
    </section>
  )
}

export default Hero
