import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import Hero from "../components/campaign-whatsapp/Hero"
import Preview from "../components/campaign-whatsapp/Preview"
import References from "../components/campaign-whatsapp/References"
import Steps from "../components/campaign-whatsapp/Steps"
import WhatsAppLeadGeneration from "../components/campaign-whatsapp/WhatsAppLeadGeneration"
import Footer from "../components/Footer"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

const Lead = () => {
  const intl = useIntl()

  return (
    <Layout noFooter>
      <SEO
        title={intl.formatMessage({ defaultMessage: "Real-time guest feedback via WhatsApp" })}
      />
      <Hero />
      <WhatsAppLeadGeneration />
      <Preview />
      <Steps />
      <References />
      <WhatsAppLeadGeneration />
      <Footer disableCallToAction />
    </Layout>
  )
}

export default Lead
