import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import { WHATSAPP } from "../../../../campaign-config"
import LeadGeneration from "../../LeadGeneration"

const WhatsAppLeadGeneration = () => {
  const intl = useIntl()

  return (
    <LeadGeneration campaign={WHATSAPP} className="whatsapp-bg-color btn-block">
      <div
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({
            defaultMessage:
              "<b>Sign up now</b> and <b>test your first event for free</b>. You’re minutes away from getting real-time feedback!",
          }),
        }}
      />
    </LeadGeneration>
  )
}

export default WhatsAppLeadGeneration
